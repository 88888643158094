import OuterDiv from "components/Common/OuterDivComponent";
import { useMenuVisibility } from "providers/menuVisibilityProvider";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Box, Breadcrumbs, Link, Typography, Tabs, Tab } from "@mui/material";
import { RootState } from "store";
import styled from "styled-components";
import StatCard from "./Components/StatCard";
import LeadList from "./Components/LeadList";
import CampaignCard from "./Components/CampaignCard";
import CustomTabPanel from "./Components/CustomTabPanel";
import { Campaign, CampaignLead } from "./types";
import { BlackListedIcon, CheckmarkIcon, FailedIcon, PausedIcon, ProfileIcon, ProgressIcon, WaitingIcon } from "assets";

const Wrapper = styled.div``;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const CampaignDetailsPage = () => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const expertId = useSelector((state: RootState) => state.auth.user.expertId);
  const { campaignData, analyticsData, combinedLeadsData, loading, error } =
    useSelector((state: RootState) => state.campaign);
  const { isMenuVisible } = useMenuVisibility()!;
  const combinedData: Campaign[] = campaignData.map((campaign: Campaign) => {
    const analytics = analyticsData.campaignAnalytics;
    return {
      ...campaign,
      analytics:
        analytics.campaign_id === campaign.campaignId ? analytics : null,
    };
  });
  const handleTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <OuterDiv {...{ isMaximizeContent: !isMenuVisible }}>
      <Wrapper>
        <Box
          sx={{
            padding: "2px 0px 0px 0px",
            boxShodow: "4px 4px 10px 0px #17171714",
            bgColor: "#000",
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{ fontSize: "16px", color: "#6d6d6d" }}
          >
            <Link underline="hover" color="inherit" href="#">
              Sr. Principal Software Engineer, Shipping Tech & Services
            </Link>
            <Typography color="text.primary" style={{ fontWeight: "bold" }}>
              { campaignData[0]?.campaignName }
            </Typography>
          </Breadcrumbs>
        </Box>

        {combinedData.map((singleCampaign: Campaign) => (
          <Box>
            <Box
              key={singleCampaign.campaignId}
              sx={{
                padding: "16px",
                margin: "16px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                bgcolor: "#fff",
              }}
            >
              <CampaignCard
                singleCampaign={singleCampaign}
                expertId={expertId}
              />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(21, 1fr)",
                  gap: 2,
                  marginTop: "15px",
                }}
              >
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="All Leads"
                    value={singleCampaign.analytics?.total_leads ?? 0}
                    Icon={ProfileIcon}
                  />
                </Box>
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="In Progress"
                    value={singleCampaign.analytics?.in_progress_leads ?? 0}
                    Icon={ProgressIcon}
                  />
                </Box>
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="Completed All steps"
                    value={singleCampaign.analytics?.finished_leads ?? 0}
                    Icon={CheckmarkIcon}
                  />
                </Box>
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="Awaiting"
                    value={singleCampaign.analytics?.waiting_leads ?? 0}
                    Icon={WaitingIcon}
                  />
                </Box>
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="Paused"
                    value={singleCampaign.analytics?.paused_leads ?? 0}
                    Icon={PausedIcon}
                  />
                </Box>
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="Failed"
                    value={singleCampaign.analytics?.failed_leads ?? 0}
                    Icon={FailedIcon}
                  />
                </Box>
                <Box sx={{ gridColumn: "span 3" }}>
                  <StatCard
                    title="Blacklisted"
                    value={singleCampaign.analytics?.blacklisted_leads ?? 0}
                    Icon={BlackListedIcon}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                margin: "16px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                bgcolor: "#fff",
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleTab}
                  aria-label="basic tabs example"
                >
                  <Tab label="Audience" {...a11yProps(0)} />
                  <Tab label="Sequence" {...a11yProps(1)} />
                  <Tab label="Statistics" {...a11yProps(2)} />
                  <Tab label="Settings" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {combinedLeadsData.length > 0 ? (
                  combinedLeadsData.map((leadObj: CampaignLead) => (
                    <LeadList key={leadObj.id} lead={leadObj} />
                  ))
                ) : (
                  <Typography>No leads available</Typography>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                Coming soon...
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                Coming soon...
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                Coming soon...
              </CustomTabPanel>
            </Box>
          </Box>
        ))}
      </Wrapper>
    </OuterDiv>
  );
};
