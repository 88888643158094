import { API_URL_PREFIX, DEFAULT_TOKEN } from '../utilities/constants';
import { post } from '../utilities';

const getCampaingDetails = async (payload: { jobId: string; expertId: string; token: string }) => {
    return await post(`${API_URL_PREFIX}/campaign/getCampaigns`, payload);
};

const getCampaignAnalytics = async (payload: {
    token: string;
    expertId: string;
    jobId: string;
    campaignId: string;
  }) => {
    return await post(`${API_URL_PREFIX}/campaign/getCampaignAnalytics`, payload);
  };

  const getCampaignLeads = async (payload: {
    token: string;
    expertId: string;
    jobId: string;
    campaignId: string;
    leadIds: number[];
  }) => {
    return await post(`${API_URL_PREFIX}/campaign/getCampaignLeadsAnalytics`, payload);
  };
  
  export const campaignManagementService = {
    getCampaingDetails,
    getCampaignAnalytics,
    getCampaignLeads,
  };