import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

const StatCard = ({
  title,
  value,
  additionalInfo,
  Icon,
}: {
  title: string;
  value: number;
  additionalInfo?: string;
  Icon: any;
}) => (
  <Card sx={{ border: "1px solid #e3e8ef", borderRadius: "10px" }}>
    <CardContent
      sx={{
        padding: "13px",
        "&:last-child": {
          paddingBottom: "14px",
        },
      }}
    >
      <Typography sx={{ display: "flex", alignItems: "center", gap: "10px", color: "#315cd5",  }}>
        <img style={{ top: '4px', right: '0', width: '19px', height: "19px" }} alt="icon" src={Icon} />
        {value}
      </Typography>
      <Typography variant="caption" color="#BABABA">
        {title}
      </Typography>
    </CardContent>
  </Card>
);

export default StatCard;
