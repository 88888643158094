import { createSlice } from "@reduxjs/toolkit";
import { fetchCampaignAndAnalytics } from "./campaignActions";

interface IInitialState {
  loading: boolean;
  error: string | null;
  screenBlockingLoaderView: boolean;
  campaignData: any | null;
  analyticsData: any | null;
  combinedLeadsData: any | null;
}

const initialState: IInitialState = {
  loading: false,
  error: null,
  screenBlockingLoaderView: false,
  campaignData: null,
  analyticsData: null,
  combinedLeadsData: null,
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignAndAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.screenBlockingLoaderView = true;
      })
      .addCase(fetchCampaignAndAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignData = action.payload.campaignData;
        state.analyticsData = action.payload.analyticsData;
        state.combinedLeadsData = action.payload.combinedLeadsData;
        console.log("running", "Progress");
        
        state.screenBlockingLoaderView = false;
      })
      .addCase(fetchCampaignAndAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.screenBlockingLoaderView = false;
        state.error = action.payload as string;
      });
  },
});

export default campaignSlice.reducer;
