import { Button } from "@mui/material";
import { ChevronRight } from '@styled-icons/boxicons-solid/ChevronRight';
import { addTrackOnTop } from "actions/hiringManager/JobManagement/jobManagementSlice";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { BoldSpan } from "components/CommonStyles";
import { ModalComponent } from "components/Modals/Modal";
import { getFormatLocationForSave, getLocationInOptionDataFormat } from "containers/HiringManager/Jobs.util";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { jobsManagementService } from "services/jobManagement";
import { RootState } from "store";
import styled from "styled-components";
import { CreateJobRequest, FormDescriptionDataType, JobDetails, JobProfileDataType, JobTrack } from "types/JobManagement";
import { DefaultToastSettings } from "utilities/defaults";
import AddJobProfile from "../Components/CreateJobComponent/AddJobProfile";
import JobDetailsForm from "../Components/CreateJobComponent/JobDetailsForm";
import JobProfilePage from "../Components/CreateJobComponent/JobProfilePage";
import JobStep from "../Components/CustomizedMuiSteps";
import { OptionsDataType } from "types/Jobs";
import { isNumber } from "lodash";

type IProps = {
    setShowCreateJob: (show: boolean) => void;
    handleAfterJobSave: (updatedJobDetails: CreateJobRequest) => void;
    mode: 'CREATE_JOB' | 'EDIT_JOB',
    jobDetails?: CreateJobRequest & { selectedLocations: OptionsDataType[] };
}

const DefaultFormData: CreateJobRequest & { selectedLocations: OptionsDataType[] } = {
    jobDescription: '',
    agencyRewardFixedAmount: null,
    agencyRewardPercentageOfAnnualSalary: null,
    agencyRewardPercentageOfHourlyRate: null,
    currency: 'USD',
    timezone: 'America/New_York',
    employmentType: 'FULL_TIME',
    equity: "CASH_ANDOR_EQUITY",
    jobAccess: 'PRIVATE',
    jobTitle: "",
    jobType: 'ONSITE',
    locations: [],
    selectedLocations: [],
    maxSalary: null,
    minSalary: null,
    openPositions: null,
    selectionSteps: [],
    compensationFrequency: null,
    title: '',
    trackId: '',
    attributes: [],
    startTime: '10:00 AM',
    endTime: '07:00 PM',
    numberOfDaysOfficeEveryWeek: null,
    expertId: '',
    jobId: ''
}

const StepsTitle = ['Choose a Job Profile', 'Enter Job Details'];
const AddJobProfileSteps = ['Add a New Job Profile', 'Add Interview Rounds'];

const StyledContainer = styled.div`
    span, input, .MuiSelect-select {
        font-size: 16px !important;
    }
    .info-text {
        font-size: 14px !important;
        display: inline-block;
    }
    .steps-container {
        width: 150px;
    }
    .steps-form-container {
        overflow: auto;
        max-height: 70vh;
    }
    .MuiSelect-select {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .input-element {
        box-shadow: none;
        outline: 1px solid #bfd1ff;
        padding: 0 0.5rem;
        border-radius: 3px;
        width: 100%;
        box-sizing: border-box;
    }
    .input-element.Mui-focused {
        outline-width: 2px;
    }
    .input-element-margin {
        margin-top: 0.4rem;
    }
    .input-row {
        display: flex;
        justify-content: space-start;
        
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: #bfd1ff !important;
    }
    .input-support-container {
        height: 2.1rem; 
        width: 3rem;
        border: 1px solid #bfd1ff;
        outline: none;
        border-left: none;
        padding-top: 3px;
    }
    .individual-radio-btn {
        margin-left: -11px
    }
`

const CreateJobModalContainer = (props: IProps) => {

    const dispatch = useDispatch();
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const [currStepAddJob, setCurrStepAddJob] = useState<number>(0);
    const [stepAddJobProfile, setStepAddJobProfile] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const createJobFormData = useRef<CreateJobRequest & { selectedLocations: OptionsDataType[] }>(
        props.jobDetails ? {
            ...DefaultFormData,
            ...props.jobDetails,
            selectedLocations: !!props.jobDetails?.selectedLocations ? props.jobDetails?.selectedLocations : getLocationInOptionDataFormat(props.jobDetails.locations),
            compensationFrequency: props.jobDetails?.compensationFrequency ? props.jobDetails.compensationFrequency :
                isNumber(props.jobDetails.agencyRewardFixedAmount) ? "FIXED" :
                    isNumber(props.jobDetails.agencyRewardPercentageOfAnnualSalary) ? "ANNUALLY" : "HOURLY",
        } : { ...DefaultFormData });
    const createJobFormAction = useRef<'next' | 'prev' | ''>('');
    const [showAddJobProfile, setShowAddJobProfile] = useState<boolean>();
    const jobProfileSubmitBtnRef = useRef<any>();
    const jobDetailSubmitBtnRef = useRef<any>();

    const addJobProfile = useRef<any>();    // used as submit btn for both add job profile and save job profile
    const [addJobProfileFormAction, setAddJobProfileFormAction] = useState<'next' | 'prev' | ''>('');
    const stepsContainerContainerRef = useRef<HTMLDivElement | null>(null);

    const moveToNext = () => {
        setCurrStepAddJob(prev => prev + 1);
    }

    const moveToPrev = () => {
        setCurrStepAddJob(prev => prev - 1);
    }

    const saveFormData = (value: FormDescriptionDataType | (JobDetails<true> & { selectedLocations: OptionsDataType[] }) | JobProfileDataType) => {
        createJobFormData.current = { ...createJobFormData.current, ...value };
    }

    const handleSubmit = (value: FormDescriptionDataType | (JobDetails<true> & { selectedLocations: OptionsDataType[] }) | JobProfileDataType) => {
        saveFormData(value);
        if (createJobFormAction.current === 'next') {
            moveToNext();
        } else if (createJobFormAction.current === '') {
            if (props.mode === "CREATE_JOB") {
                handleSubmitCreateJob();
            } else if (props.mode === "EDIT_JOB") {
                handleSubmitEditJob();
            }
        }
    }

    const handleSubmitEditJob = () => {
        setLoading(true);
        jobsManagementService.updateJob({
            ...createJobFormData.current,
            expertId,
            locations: getFormatLocationForSave(createJobFormData.current.selectedLocations)
        })
            .then((res: any) => {
                toast.success("Job saved successfully", DefaultToastSettings);
                props.handleAfterJobSave && props.handleAfterJobSave(res.output);
                setLoading(false);
            })
            .catch((e) => {
                toast.error(e.message, DefaultToastSettings);
                setLoading(false);
            })
    }

    const handleSubmitCreateJob = () => {
        setLoading(true);
        jobsManagementService.createJobAction({
            ...createJobFormData.current,
            expertId,
            locations: getFormatLocationForSave(createJobFormData.current.selectedLocations)
        })
            .then((res: any) => {
                toast.success("Job created successfully", DefaultToastSettings);
                props.handleAfterJobSave && props.handleAfterJobSave(res.output);
                setLoading(false);
            })
            .catch((e) => {
                toast.error(e.message, DefaultToastSettings);
                setLoading(false);
            })
    }

    const clickSubmit = () => {
        if (currStepAddJob === 0 && jobProfileSubmitBtnRef.current) {
            jobProfileSubmitBtnRef.current.click();
        } else if (currStepAddJob === 1 && jobDetailSubmitBtnRef.current) {
            jobDetailSubmitBtnRef.current.click();
        }
    }

    const afterJobProfileCreated = (track: JobTrack) => {
        createJobFormData.current = { ...createJobFormData.current, trackId: track.trackId };
        setShowAddJobProfile(false);
        dispatch(addTrackOnTop({ track }));
    }

    useEffect(() => {
        if (stepsContainerContainerRef.current !== null) {
            stepsContainerContainerRef.current.scrollTop = 0;
        }
    }, [currStepAddJob])

    return (
        <ModalComponent
            show={true}
            showCloseIcon={true}
            size="xl"
            isStatic={true}
            handleClose={() => props.setShowCreateJob(false)}
            header={<div className="d-flex align-items-center">
                <span>Add a Job</span>
                {showAddJobProfile && <>
                    <ChevronRight width={'26px'} className="text-muted mx-3" />
                    <span>{AddJobProfileSteps[stepAddJobProfile]}</span>
                </>}
            </div>}
            headerStyling={{
                borderBottom: '1px solid #bfd1ff',
            }}
            footer={
                <div className="d-flex justify-content-between align-item-center w-100">
                    {
                        showAddJobProfile ?
                            <>
                                <div>
                                    {stepAddJobProfile > 0 && <Button type="button" variant="text"
                                        onClick={() => {
                                            setAddJobProfileFormAction('prev');
                                            setStepAddJobProfile(stepAddJobProfile - 1);
                                        }}>{'Previous Step'}</Button>}
                                </div>
                                <div>
                                    <Button type="button" variant="outlined" className="mr-3"
                                        onClick={() => setShowAddJobProfile(false)}>{'Select another Job Profile'}</Button>
                                    {stepAddJobProfile === 0 && <Button type="button" variant="contained" className="mr-3"
                                        onClick={() => {
                                            setAddJobProfileFormAction('next');
                                            addJobProfile.current?.click();

                                        }}>{'Add Interview Rounds'}</Button>}
                                    {stepAddJobProfile === 1 && <Button type="button" variant="contained" className="mr-3"
                                        onClick={() => {
                                            setAddJobProfileFormAction('');
                                            addJobProfile.current?.click();

                                        }}>{'Save job profile'}</Button>}
                                </div>
                            </>
                            : (
                                <>
                                    <div>
                                        {currStepAddJob > 0 && <Button type="button" variant="text"
                                            onClick={() => {
                                                createJobFormAction.current = 'prev';
                                                moveToPrev();
                                            }}>{'Previous Step'}</Button>}
                                    </div>
                                    <div>
                                        <Button type="button" variant="outlined" className="mr-3"
                                            onClick={() => props.setShowCreateJob(false)}>{'Discard'}</Button>
                                        {currStepAddJob === 0 && props.mode !== "EDIT_JOB" && <Button type="button" variant="outlined" className="mr-3"
                                            onClick={() => setShowAddJobProfile(true)}>{'Add a New Job Profile'}</Button>}
                                        {currStepAddJob < StepsTitle.length - 1 && <Button type="button" variant="contained"
                                            onClick={() => {
                                                createJobFormAction.current = 'next';
                                                clickSubmit();
                                            }}>{'Next'}</Button>}
                                        {currStepAddJob === StepsTitle.length - 1 && <Button type="button" variant="contained"
                                            onClick={() => {
                                                createJobFormAction.current = '';
                                                clickSubmit();
                                            }}>{props.mode === "EDIT_JOB" ? 'Save Job' : 'Add Job'}</Button>}
                                    </div>
                                </>
                            )
                    }
                </div>
            }
            footerStyling={{
                border: 'none',
                backgroundColor: '#f1f3fa'
            }}
        >
            <StyledContainer>
                <OverlayLoader loading={loading} />
                {!showAddJobProfile && <div className="d-flex justify-content-between my-4">
                    <BoldSpan>Step {currStepAddJob + 1}/{StepsTitle.length}: {StepsTitle[currStepAddJob]}</BoldSpan>
                    <div className="steps-container">
                        <JobStep
                            activeStep={currStepAddJob}
                            totalSteps={StepsTitle.length}
                        />
                    </div>
                </div>}
                <div className="steps-form-container" ref={stepsContainerContainerRef}>
                    {
                        showAddJobProfile ? <div className="my-4">
                            <AddJobProfile
                                submitBtnRef={addJobProfile}
                                stepAddJobProfile={stepAddJobProfile}
                                setStepAddJobProfile={setStepAddJobProfile}
                                addJobProfileFormAction={addJobProfileFormAction}
                                afterJobProfileCreated={afterJobProfileCreated}
                            />
                        </div>
                            : (
                                <>
                                    {
                                        currStepAddJob === 0 && <JobProfilePage
                                            initialValues={{ ...createJobFormData.current }}
                                            handleSubmit={handleSubmit}
                                            submitBtnRef={jobProfileSubmitBtnRef}
                                        />
                                    }
                                    {
                                        currStepAddJob === 1 && <JobDetailsForm
                                            initialValues={{ ...createJobFormData.current }}
                                            handleSubmit={handleSubmit}
                                            submitBtnRef={jobDetailSubmitBtnRef}
                                            handleFormChange={saveFormData}
                                        />
                                    }
                                </>
                            )
                    }
                </div>
            </StyledContainer>
        </ModalComponent>
    )
}

export default CreateJobModalContainer;