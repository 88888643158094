import { Autocomplete, Checkbox, CircularProgress, TextField } from '@mui/material';
import { convertLocationToOptionDataType } from 'containers/HiringManager/Jobs.util';
import { State } from 'country-state-city';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { jobsService } from 'services/jobs';
import { RootState } from 'store';
import { ICityExpert, ICountryExpert, IStateExpert, MAP_BOX_LOCATION_TYPE, OptionsDataType } from 'types/Jobs';
import {useEffect} from "react"

type IProps = {
    selectedLocations: OptionsDataType[];
    handleChangeLocation: (key: string, value: OptionsDataType[]) => void;
    disabled: boolean;
    keyName: string;
    hideCheckboxInOption?: boolean;
}

const JobLocationSelector = (props: IProps) => {

    const [locationData, setLocationData] = useState<OptionsDataType[]>([]);
    const [loadingLocation, setLoadingLocation] = useState<boolean>(false);
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);

    const debounceLocationData = useCallback(
        debounce((filterString: string) => {
            if (filterString) {
                setLoadingLocation(true);
                
                if (!expertId) {
                    console.error("Expert ID is missing.");
                    setLoadingLocation(false);
                    return;
                }

                jobsService
                    .getLocationFinderMapBox(filterString, expertId)
                    .then((res) => {
                        if (res && res.output && Array.isArray(res.output.suggestions)) {
                            const options = getOptionData(res.output.suggestions);
                            setLocationData(options);
                        } else {
                            console.error("Unexpected API response:", res);
                            setLocationData([]);
                        }
                    })
                    .catch((err) => {
                        console.error("Error fetching location data:", err);
                        setLocationData([]);
                    })
                    .finally(() => setLoadingLocation(false));
            } else {
                setLocationData([]);
            }
        }, 400),
        [expertId] 
    );

    useEffect(() => { 
        if (!expertId) {
            setLocationData([]);
        }
    }, [expertId]);

    const getOptionData = (locations: MAP_BOX_LOCATION_TYPE[]): OptionsDataType[] => {
        if (!locations || locations.length === 0) {
            return [];
        }
        const countries: ICountryExpert[] = [];
        const states: IStateExpert[] = [];
        const cities: ICityExpert[] = [];
        const addedIds = new Set<string>();
        for (const location of locations) {
            const address = location.context;
            // location is city
            if (address.district && address.region) {
                const [countryCode, stateCode] = address.region.regionCodeFull.toUpperCase().split("-");
                const key = countryCode + "-" + stateCode + "-" + location.name;
                if (!addedIds.has(key)) {
                    cities.push({
                        name: location.name,
                        countryCode,
                        stateCode,
                        id: location.mapboxId
                    });
                    addedIds.add(key);
                }
            }
            // location is state
            else if (location.featureType === 'region' && address.country) {
                const countryCode = address.country.countryCode.toUpperCase();
                const stateCode = State.getStatesOfCountry(countryCode).find(state => state.name.toUpperCase() === location.name.toUpperCase())?.isoCode;
                const key = countryCode + "-" + stateCode;
                if (stateCode && !addedIds.has(key)) {
                    states.push({
                        name: location.name,
                        countryCode,
                        isoCode: stateCode,
                        id: location.mapboxId
                    });
                    addedIds.add(key);
                }
            }
            // location is country
            else if (address.country) {
                const countryCode = address.country.countryCode.toUpperCase();
                if (!addedIds.has(countryCode)) {
                    countries.push({ name: location.name, isoCode: countryCode, id: location.mapboxId });
                    addedIds.add(countryCode);
                }
            }
        }
        return convertLocationToOptionDataType(countries, states, cities);
    }

    return (
        <Autocomplete
            id="location-selector"
            disablePortal
            disableCloseOnSelect
            value={props.selectedLocations}
            isOptionEqualToValue={(option, value) => option.elementValue === value.elementValue && option.parent === value.parent}
            getOptionLabel={(option) => option.element}
            options={locationData}
            onChange={(e, value) => props.handleChangeLocation(props.keyName, value)}
            multiple
            loading={loadingLocation}
            filterOptions={(options) => options}
            noOptionsText={'Please search for a continent, country, state, or city'}
            renderOption={(renderProps, option, { selected }) => {
                if (props.hideCheckboxInOption) {
                    return (<li {...renderProps}>
                        {option.element}
                    </li>)
                }
                return (
                    <li {...renderProps}>
                        <Checkbox
                            style={{ marginRight: 8, color: '#315CD5' }}
                            checked={selected}
                        />
                        {option.element}
                    </li>
                )
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={'Enter a continent, country, state, or city to search'}
                    variant="outlined"
                    onChange={(e) => {
                        debounceLocationData(e.target.value)
                    }}
                    onBlur={(e) => { debounceLocationData(e.target.value) }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <div>
                                {loadingLocation ? <CircularProgress color="primary" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </div>
                        ),
                    }}
                    size='small'
                />
            )}
        />
    );
};

export default JobLocationSelector;