import VerticalTrackCardLeftImage from './background/selectTracksPage/verticalTrackCardLeftImage.svg';
import VerticalTrackCardMiddleImage from './background/selectTracksPage/verticalTrackCardMiddleImage.svg';
import VerticalTrackCardRightImage from './background/selectTracksPage/verticalTrackCardRightImage.svg';

import HumanHeadIcon from './icons/selectTracksPage/humanHeadIcon.svg';
import WebDevelopmentIcon from './icons/selectTracksPage/webDevelopmentIcon.svg';

import HumanGroupIcon from './icons/selectTracksPage/humanGroup.svg';
import HumanLaptopLightIcon from './icons/selectTracksPage/humanLaptopLight.svg';
import HumanLaptopDarkIcon from './icons/selectTracksPage/humanLaptopDark.svg';

import StarBadgeIcon from './icons/b2b/starBadge.svg';
import CheckPerformanceIcon from './icons/b2b/checkPerformance.svg';
import InterviewRequestIcon from './icons/b2b/interviewRequest.svg';
import FinaliseIcon from './icons/b2b/finalise.svg';
import RejectIcon from './icons/b2b/reject.svg';

import SideListIcon from "./icons/campaign/sideListIcon.svg";
import BlackListedIcon from "./icons/campaign/blacklisted.svg";
import CheckmarkIcon from "./icons/campaign/checkmark.svg";
import FailedIcon from "./icons/campaign/failed.svg";
import PausedIcon from "./icons/campaign/paused.svg";
import ProfileIcon from "./icons/campaign/profile.svg";
import ProgressIcon from "./icons/campaign/progress.svg";
import WaitingIcon from "./icons/campaign/waiting.svg";


import { BASE_ASSETS_URL, DEFAULT_MARKET_NAME } from '../utilities/constants';

const BulbIcon = `${BASE_ASSETS_URL}/${DEFAULT_MARKET_NAME}/icons/bulbIcon.svg`;
const BlueInterviewHelpLogoIcon = `${BASE_ASSETS_URL}/${DEFAULT_MARKET_NAME}/icons/logos/blueInterviewHelpLogoIcon.svg`;
const WhiteInterviewHelpLogoIcon = `${BASE_ASSETS_URL}/${DEFAULT_MARKET_NAME}/icons/logos/whiteInterviewHelpLogoIcon.svg`;


export {
  VerticalTrackCardLeftImage,
  VerticalTrackCardMiddleImage,
  VerticalTrackCardRightImage,
  BlueInterviewHelpLogoIcon,
  WhiteInterviewHelpLogoIcon,
  BulbIcon,
  HumanHeadIcon,
  WebDevelopmentIcon,
  HumanGroupIcon,
  HumanLaptopDarkIcon,
  HumanLaptopLightIcon,
  StarBadgeIcon,
  CheckPerformanceIcon,
  InterviewRequestIcon,
  FinaliseIcon,
  RejectIcon,
  BlackListedIcon,
  CheckmarkIcon,
  FailedIcon,
  SideListIcon,
  PausedIcon,
  ProfileIcon,
  ProgressIcon,
  WaitingIcon,
};
