import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { ProfileIcon } from "assets";
import React from "react";
import { MoreVerticalOutline } from "styled-icons/evaicons-outline";
import { getOnlyDateTimeInLocalFormat } from "utilities/commonUtils";

const ACTION_BUTTON_STYLE = {
  width: "20px",
  cursor: "pointer",
  color: "#325cd5",
};
interface LeadListProps {
  lead: Leads | null;
}
interface Leads {
  acceptance_rate: number;
  total_leads: number;
  in_progress_leads: number;
  failed_leads?: number;
  blacklisted_leads?: number;
  reply_rate?: number;
  [key: string]: any;
}
const LeadList: React.FC<LeadListProps> = ({ lead }) => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">Lists of leads</Typography>
        <Button variant="contained">Coming soon</Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "8px",
          border: "1px solid #E0E0E0",
          marginTop: "10px",
          padding: "10px 15px",
          bgcolor: "#fff",
        }}
      >
        <Box display="flex" alignItems="center" gap="16px">
          <Box
            height="50px"
            borderRadius="8px"
            bgcolor="#f5f5f5"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src="https://via.placeholder.com/40" alt="Icon" />
          </Box>
          <Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#315cd5",
                }}
              >
                {lead && lead?.name}
              </Typography>
              <Tooltip title="Info about DevOps Coach" arrow>
                <IconButton
                  size="small"
                  style={{ padding: "0px", marginLeft: "4px" }}
                ></IconButton>
              </Tooltip>
            </Box>
            <Box
              position="relative"
              width="50%"
              height={8}
              borderRadius="4px"
              bgcolor="#e0e0e0"
            >
              <Box
                position="absolute"
                width={`${(2 / 21) * 100}%`}
                height="100%"
                bgcolor="green"
                borderRadius="4px"
              />
              <Box
                position="absolute"
                left={`${(2 / 21) * 100}%`}
                width={`${(19 / 21) * 100}%`}
                height="100%"
                bgcolor="orange"
                borderRadius="4px"
              />
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop="6px"
              >
                <Typography variant="caption" color="green" marginTop="10px">
                  2
                </Typography>
                <Typography variant="caption" color="orange" marginTop="10px">
                  19
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box textAlign="center" marginRight="16px">
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ display: "flex", alignItems: "center", gap: "2px", color: "#315cd5" }}>
              <img style={{ top: '4px', right: '0', width: '19px', height: "19px" }} alt="icon" src={ProfileIcon} />
              {lead?.totalLeadsCount}
            </Typography>
          </Box>
          <Typography variant="caption" color="gray">
            Lists of leads
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="10px"
        >
          <img src="https://via.placeholder.com/40" alt="Icon" />
          <Box>
            <Box>
              <Typography>Coming soon</Typography>
            </Box>
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box textAlign="center">
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography variant="h6">{lead?.acceptanceRate}%</Typography>
            <Typography variant="h6" sx={{ color: "#315cd5" }}>
              {lead?.acceptanceCount}
            </Typography>
          </Box>
          <Typography variant="caption" color="gray">
            Acceptance Rate
          </Typography>
        </Box>

        <Box textAlign="center">
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography variant="h6">{lead?.replyRate}%</Typography>
            <Typography variant="h6" sx={{ color: "#315cd5" }}>
              {lead?.replyCount}
            </Typography>
          </Box>
          <Typography variant="caption" color="gray">
            Reply rate
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          <Box sx={{ display: "flex" }} textAlign="start">
            <Box>
              <Typography variant="h6">
                {getOnlyDateTimeInLocalFormat(new Date(lead?.createdAt))}
              </Typography>
              <Typography variant="caption" color="gray">
                Created
              </Typography>
            </Box>
          </Box>
        </Box>

        <MoreVerticalOutline style={{ ...ACTION_BUTTON_STYLE }} />
      </Box>
    </>
  );
};

export default LeadList;
