import { createAsyncThunk } from "@reduxjs/toolkit";
import { campaignManagementService } from "services/campaign";

export const fetchCampaignAndAnalytics = createAsyncThunk(
  "campaign/fetchCampaignAndAnalytics",
  async (
    payload: { jobId: string; expertId: string; token: string },
    { rejectWithValue }
  ) => {
    try {
      const campaignResponse =
        await campaignManagementService.getCampaingDetails(payload);
      const campaignData = campaignResponse.output.campaigns;
      const campaignId = campaignData[0]?.campaignId;

      if (campaignId == null || campaignId === "") {
        throw new Error("Campaign ID not found");
      }

      const analyticsResponse =
        await campaignManagementService.getCampaignAnalytics({
          ...payload,
          campaignId,
        });
      const analyticsData = analyticsResponse.output;
      const campaignLeads = analyticsResponse.output.campaignLeads;
      const leadIds = analyticsResponse.output.campaignLeads.map(
        (lead: { id: string }) => lead.id
      );
      if (!leadIds || leadIds.length === 0) {
        return {
          campaignData,
          analyticsData,
          combinedLeadsData: [],
        };
      }
      const leadsResponse = await campaignManagementService.getCampaignLeads({
        ...payload,
        campaignId,
        leadIds: leadIds,
      });
      const leadStatistics = leadsResponse.output.lead_statistics;
      const combinedLeadsData = campaignLeads.map((lead: { id: string }) => {
        const stats = leadStatistics.find(
          (stat: { id: string }) => stat.id === lead.id
        );
        return {
          ...lead,
          ...stats,
        };
      });
      return {
        campaignData,
        analyticsData,
        combinedLeadsData,
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
